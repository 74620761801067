@import url(https://fonts.googleapis.com/css?family=Lobster|Righteous&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#scoringTable {
    margin: 0 auto;
    font-weight: bold;
    border-collapse: collapse;
    color: gold;
    background-color: navy;
    border: 5px solid gold;
}

td:nth-child(1) {
    text-align: left;
    padding-left: 4px;
}

td:nth-child(2) {
    background-color: red;
}

td:nth-child(n+2) {
    border-left: 5px solid gold;
    text-align: right;
    width: 100px;
    padding-right: 4px;
}

@media screen and (max-width: 414px) {
    table {
        font-size: .5em;
    }
}

@media screen and (max-width: 767px) {
    table {
        font-size: .8em;
    }
}

.card {
    background-color: #ffffff;
    color: black;
    background-image: url("https://www.transparenttextures.com/patterns/absurdity.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
    border-radius: 15px;
    border: 5px solid transparent;
    font-family: 'lobster', cursive;
    box-sizing: border-box;
    width: 10em;
    height: 14em;
    margin: 1em;
    padding: 5px; 
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    
  }
  
.card:hover {
    cursor: pointer;
}

.cardValue {
    height: 88px;
}

.cardSymbol {
    text-align: center;
    font-size: 4em;
}

.cardSuite {
    height: 88px;
}

.handDisplay {
    display: flex;
    /* flex-direction: row; */
    margin: 1em auto 0 auto;
    height: 16em;
    max-width: 60em;
}

.isKept {
    border: 5px solid gold; 
    box-shadow: 0px 10px 13px -7px gold, 5px 5px 15px 5px rgba(0,0,0,0);
}
  
.keepIndicator {
    color: gold;
    margin-top: 1.5em;
}

@media screen and (min-width: 768px) {
    .keepIndicator {margin-top: -0.3em;}
}

@media screen and (max-width: 767px) {
    .cardValue, .cardSuite {
        height: 44px
    }

    .card .cardSymbol {
        text-align: center;
        font-size: 2em !important;
    }

    .handDisplay {
        display: flex;
        /* flex-direction: row; */
        margin: 1em auto 0 auto;
        height: 9em;
        max-width: 60em;
        }

    .keepIndicator {
        color: gold;
        margin-top: 1em;
        font-size: 1.25em;
    }
}



@media screen and (max-width: 767px) {
    .card {
        height: 7em;
    }


}

@media screen and (min-width: 415px) {
  
    .handDisplay {
        margin-bottom: 1em;
    }
    
}

@media screen and (max-width: 414px) {
    /* For Galaxy S5: */
    .cardValue, .cardSuite {
        height: 44px
    }

    .card .cardSymbol {
        text-align: center;
        font-size: 2em !important;
    }
    
    .card {
        background-color: #ffffff;
        color: black;
        background-image: url("https://www.transparenttextures.com/patterns/absurdity.png");
      /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
        border-radius: 15px;
        border: 5px solid transparent;
        font-family: 'lobster', cursive;
        box-sizing: border-box;
        width: 5em;
        height: 7em;
        margin: 1em;
        margin-left: .5em;
        margin-right: .5em;
        padding: 5px; 
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
        
    }

    .handDisplay {
        display: flex;
        /* flex-direction: row; */
        margin: 0 auto;
        height: 9em;
        max-width: 60em;
        }

    .keepIndicator {
        color: gold;
        font-size: .8em;
        margin-top: 1.5em;
    }

    .isKept {
        border: 5px solid gold; 
        box-shadow: 0px 10px 13px -7px gold, 5px 5px 15px 5px rgba(0,0,0,0);
    }
}
.slot {
	height: 4em;
	width: 4em;
	background: black;
	border: 0.4em solid #555;
    border-radius: 0.3em;
    padding: .1em;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: red;
}

.slot:hover {
    cursor: pointer;
}

.slot:active {
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,0,0,1) 0%, rgba(0,0,0,1) 100%);
}


.slotInnerBorder {
    border: .1em solid red;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: row;

}

.slotInnerLeft {
    border-right: .1em solid red;
    width: 30%;
}

.slotInnerRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: .0101em;
}


.App-link {
  color: #61dafb;
}

body {
  background-color: #35a81b;
  background-image: url("https://www.transparenttextures.com/patterns/debut-light.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.black {
  color: black;
}

.betValue {
  box-shadow:inset 0px 0px 15px 3px #35a81b;
  box-shadow: inset 0px 0px 10px 5px rgba(0,0,0,0.5);
	background-color:transparent;
	border-radius:50%;
	display:inline-block;
  color:#ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
	font-family:'Lobster', serif;
  font-size:2em;
  height: 2em;
	padding:6px;
	text-decoration:none;
  text-shadow:0px 1px 0px #5b8a3c;
  width: 2em;
}

.betLogo {
  font-family: 'Righteous', cursive;
  color: white;
  font-size: 2em;
  margin-bottom: 0em;
}

.center-text {
  text-align:center;
}

.center-to-bottom {
  display: flex !important;
  justify-content: center !important;
  align-items: flex-end !important;
  margin-top:1em;
  margin-bottom: 1em;
}

.to-bottom {
  flex-direction: column;
}

.creditsCount {
  margin-bottom: 0;
  color: white; 
}

h1 {
  font-family: "Righteous", cursive;
  color: white;
}


.red {
  color: red;
}

/* media queries */

@media screen and (max-width: 767px) {

  .betLogo {
    font-family: 'Righteous', cursive;
    color: white;
    font-size: 1em;
    margin-bottom: 0em;
  }

  .creditsCount {
    font-size:1em;
    font-weight:bold;
  }


}

@media screen and (min-width:415px) {
  .lower-button {
    text-align: center;
  }
}

@media screen and (max-width: 414px) {
  .betValue {
    box-shadow:inset 0px 0px 15px 3px #35a81b;
    box-shadow: inset 0px 0px 10px 5px rgba(0,0,0,0.5);
    background-color:transparent;
    border-radius:50%;
    display:inline-block;
    color:#ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:'Lobster', serif;
    font-size:2em;
    height: 1em;
    padding:6px;
    width: 1em;
  }
  .betLogo {
    font-family: 'Righteous', cursive;
    color: white;
    font-size: 1em;
    margin-bottom: 0em;
  }

  .creditsCount {
    font-size:1em;
    font-weight:bold;
  }
}


@media screen and (max-width: 414px) {
    h1 {font-size: 2.25em;
    margin-bottom: 0;
    }

    #betOptions {
      padding-bottom: 1em;
    }
}

  
button {
	box-shadow: 0px 1px 0px 0px gold;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(5%, gold), to(#ffab23));
	background:linear-gradient(to bottom, gold 5%, #ffab23 100%);
	background-color:gold;
	border:1px solid #ffaa22;
	display:inline-block;
	cursor:pointer;
	color:black;
	font-family:Arial;
	font-size:15px;
    font-weight:bold;
    height: 60px;
	padding:12px 24px;
    text-decoration:none;
    width: 135px;
	text-shadow:0px 1px 0px #ffee66;
}
button:hover {
	background:-webkit-gradient(linear, left top, left bottom, color-stop(5%, #ffab23), to(#ffec64));
	background:linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
	background-color:#ffab23;
}
button:active {
	position:relative;
	top:1px;
}

button:disabled {
    opacity: 0.5;
    background:-webkit-gradient(linear, left top, left bottom, color-stop(5%, #ffab23), to(#ffec64));
    background:linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
    cursor:default;
    
  }
  
button:focus {
    outline:0;
}

#betOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
 
        padding-bottom: 1em;
      
    

}

.betButton {
    border-radius:28px;
    width: 70px;
    font-size: 2em;
    margin-left: .25em;
    margin-right: .25em;
}

#dealButton:disabled {
    opacity: 0.5
}

@media screen and (max-width: 414px) {
    button {
        box-shadow: 0px 1px 0px 0px gold;
        background:-webkit-gradient(linear, left top, left bottom, color-stop(5%, gold), to(#ffab23));
        background:linear-gradient(to bottom, gold 5%, #ffab23 100%);
        background-color:gold;
        border:1px solid #ffaa22;
        display:inline-block;
        cursor:pointer;
        color:black;
        font-family:Arial;
        font-size:8px;
        font-weight:bold;
        height: 40px;
        padding:12px 24px;
        text-decoration:none;
        width: 67px;
        text-shadow:0px 1px 0px #ffee66;
    }

    .betButton {
        width: 30px;
        height: 20px;
        font-size: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
}


