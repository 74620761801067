.App-link {
  color: #61dafb;
}

body {
  background-color: #35a81b;
  background-image: url("https://www.transparenttextures.com/patterns/debut-light.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.black {
  color: black;
}

.betValue {
  box-shadow:inset 0px 0px 15px 3px #35a81b;
  -webkit-box-shadow: inset 0px 0px 10px 5px rgba(0,0,0,0.5);
  -moz-box-shadow: inset 0px 0px 10px 5px rgba(0,0,0,0.5);
  box-shadow: inset 0px 0px 10px 5px rgba(0,0,0,0.5);
	background-color:transparent;
	border-radius:50%;
	display:inline-block;
  color:#ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
	font-family:'Lobster', serif;
  font-size:2em;
  height: 2em;
	padding:6px;
	text-decoration:none;
  text-shadow:0px 1px 0px #5b8a3c;
  width: 2em;
}

.betLogo {
  font-family: 'Righteous', cursive;
  color: white;
  font-size: 2em;
  margin-bottom: 0em;
}

.center-text {
  text-align:center;
}

.center-to-bottom {
  display: flex !important;
  justify-content: center !important;
  align-items: flex-end !important;
  margin-top:1em;
  margin-bottom: 1em;
}

.to-bottom {
  flex-direction: column;
}

.creditsCount {
  margin-bottom: 0;
  color: white; 
}

h1 {
  font-family: "Righteous", cursive;
  color: white;
}


.red {
  color: red;
}

/* media queries */

@media screen and (max-width: 767px) {

  .betLogo {
    font-family: 'Righteous', cursive;
    color: white;
    font-size: 1em;
    margin-bottom: 0em;
  }

  .creditsCount {
    font-size:1em;
    font-weight:bold;
  }


}

@media screen and (min-width:415px) {
  .lower-button {
    text-align: center;
  }
}

@media screen and (max-width: 414px) {
  .betValue {
    box-shadow:inset 0px 0px 15px 3px #35a81b;
    -webkit-box-shadow: inset 0px 0px 10px 5px rgba(0,0,0,0.5);
    -moz-box-shadow: inset 0px 0px 10px 5px rgba(0,0,0,0.5);
    box-shadow: inset 0px 0px 10px 5px rgba(0,0,0,0.5);
    background-color:transparent;
    border-radius:50%;
    display:inline-block;
    color:#ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:'Lobster', serif;
    font-size:2em;
    height: 1em;
    padding:6px;
    width: 1em;
  }
  .betLogo {
    font-family: 'Righteous', cursive;
    color: white;
    font-size: 1em;
    margin-bottom: 0em;
  }

  .creditsCount {
    font-size:1em;
    font-weight:bold;
  }
}


@media screen and (max-width: 414px) {
    h1 {font-size: 2.25em;
    margin-bottom: 0;
    }

    #betOptions {
      padding-bottom: 1em;
    }
}

  