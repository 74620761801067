#scoringTable {
    margin: 0 auto;
    font-weight: bold;
    border-collapse: collapse;
    color: gold;
    background-color: navy;
    border: 5px solid gold;
}

td:nth-child(1) {
    text-align: left;
    padding-left: 4px;
}

td:nth-child(2) {
    background-color: red;
}

td:nth-child(n+2) {
    border-left: 5px solid gold;
    text-align: right;
    width: 100px;
    padding-right: 4px;
}

@media screen and (max-width: 414px) {
    table {
        font-size: .5em;
    }
}

@media screen and (max-width: 767px) {
    table {
        font-size: .8em;
    }
}
