button {
	box-shadow: 0px 1px 0px 0px gold;
	background:linear-gradient(to bottom, gold 5%, #ffab23 100%);
	background-color:gold;
	border:1px solid #ffaa22;
	display:inline-block;
	cursor:pointer;
	color:black;
	font-family:Arial;
	font-size:15px;
    font-weight:bold;
    height: 60px;
	padding:12px 24px;
    text-decoration:none;
    width: 135px;
	text-shadow:0px 1px 0px #ffee66;
}
button:hover {
	background:linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
	background-color:#ffab23;
}
button:active {
	position:relative;
	top:1px;
}

button:disabled {
    opacity: 0.5;
    background:linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
    cursor:default;
    
  }
  
button:focus {
    outline:0;
}

#betOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
 
        padding-bottom: 1em;
      
    

}

.betButton {
    border-radius:28px;
    width: 70px;
    font-size: 2em;
    margin-left: .25em;
    margin-right: .25em;
}

#dealButton:disabled {
    opacity: 0.5
}

@media screen and (max-width: 414px) {
    button {
        box-shadow: 0px 1px 0px 0px gold;
        background:linear-gradient(to bottom, gold 5%, #ffab23 100%);
        background-color:gold;
        border:1px solid #ffaa22;
        display:inline-block;
        cursor:pointer;
        color:black;
        font-family:Arial;
        font-size:8px;
        font-weight:bold;
        height: 40px;
        padding:12px 24px;
        text-decoration:none;
        width: 67px;
        text-shadow:0px 1px 0px #ffee66;
    }

    .betButton {
        width: 30px;
        height: 20px;
        font-size: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
}