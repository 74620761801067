.slot {
	height: 4em;
	width: 4em;
	background: black;
	border: 0.4em solid #555;
    border-radius: 0.3em;
    padding: .1em;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: red;
}

.slot:hover {
    cursor: pointer;
}

.slot:active {
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,0,0,1) 0%, rgba(0,0,0,1) 100%);
}


.slotInnerBorder {
    border: .1em solid red;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: row;

}

.slotInnerLeft {
    border-right: .1em solid red;
    width: 30%;
}

.slotInnerRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: .0101em;
}

